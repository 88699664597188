var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-backdrop","backdrop":"","shadow":"","bg-variant":"white","sidebar-class":"sidebar-lg","no-header":"","right":"","visible":_vm.customerAddSidebarActive},on:{"change":function (val) { return _vm.$emit('update:customer-add-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Nuevo cliente ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"simpleRules"},[(_vm.show)?_c('b-form',{staticClass:"p-2",on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('validation-provider',{attrs:{"name":"cliente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cliente:","label-for":"input-name"}},[_c('b-form-input',{attrs:{"id":"input-name","placeholder":"Ingresa el nombre del cliente","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"nit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NIT:","label-for":"input-nit"}},[_c('b-form-input',{attrs:{"id":"input-nit","placeholder":"Ingresa el NIT","required":""},on:{"change":_vm.changeNit},model:{value:(_vm.form.nit),callback:function ($$v) {_vm.$set(_vm.form, "nit", $$v)},expression:"form.nit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"nombre del nit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nombre del NIT:","label-for":"input-nit_name"}},[_c('b-form-input',{attrs:{"id":"input-nit_name","placeholder":"Ingresa el nombre del NIT","required":""},model:{value:(_vm.form.nit_name),callback:function ($$v) {_vm.$set(_vm.form, "nit_name", $$v)},expression:"form.nit_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Número de celular","label-for":"input-cellphone"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"input-cellphone","raw":false,"options":_vm.options.cellphoneDelimiter,"placeholder":"Número de celular"},model:{value:(_vm.form.cellphone),callback:function ($$v) {_vm.$set(_vm.form, "cellphone", $$v)},expression:"form.cellphone"}})],1),_c('b-form-group',{attrs:{"label":"Número de teléfono","label-for":"input-phone"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"input-phone","raw":false,"options":_vm.options.cellphoneDelimiter,"placeholder":"Número de teléfono"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('validation-provider',{attrs:{"name":"correo","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Correo:","label-for":"input-email"}},[_c('b-form-input',{attrs:{"id":"input-email","placeholder":"Ingresa el correo"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Fax","label-for":"input-fax"}},[_c('b-form-input',{attrs:{"id":"input-fax","placeholder":"Ingresa el Fax"},model:{value:(_vm.form.fax),callback:function ($$v) {_vm.$set(_vm.form, "fax", $$v)},expression:"form.fax"}})],1),_c('validation-provider',{attrs:{"name":"dirección","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Dirección","label-for":"textarea-address"}},[_c('b-form-textarea',{attrs:{"id":"textarea-address","placeholder":"Dirección del cliente (200 caracteres máximo)","rows":"3"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Crear ")]),_c('b-button',{staticStyle:{"margin-left":"5px"},attrs:{"type":"reset","variant":"danger"}},[_vm._v(" Limpiar ")])],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }