<template>
  <section class="invoice-add-wrapper">

    <validation-observer ref="simpleRules">
      <b-form
        v-if="show"
      >
        <b-row class="invoice-add">
          <!-- Pedido -->
          <b-col
            cols="12"
            md="6"
            xl="6"
          >
            <b-card
              class="sale-add-wrapper"
              no-body
            >
              <b-card-body>
                <b-row>
                  <!-- Col: cliente -->
                  <b-col
                    cols="12"
                    xl="6"
                    md="6"
                  >
                    <h6 class="mb-1 mt-1">
                      Cliente:
                    </h6>
                    <validation-provider
                      #default="{ errors }"
                      name="cliente"
                      rules="required"
                    >
                      <b-form-group>
                        <v-select
                          v-if="customersOptions"
                          input-id="select-customers"
                          :options="customersOptions"
                          :label="customerSelectLabel"
                          placeholder="Selecciona un cliente"
                          @input="val => setCustomerData(val)"
                          @search="onSearchCustomers"
                        >
                          <template #list-header>
                            <li
                              class="add-new-customer-header d-flex align-items-center my-50"
                              @click="customerAddSidebarActive = true"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">Nuevo cliente</span>
                            </li>
                          </template>
                          <template v-slot:option="option">
                            {{ option.nit }} - {{ option.label }}
                          </template>
                          <template slot="no-options">
                            Lo siento, no se encontraron clientes
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>

                    <!-- Info del cliente -->
                    <p v-if="customerData">
                      <!-- Nombre -->
                      <span class="font-weight-bold">Nombre:</span>
                      {{ customerData.name }}
                      <br>
                      <!-- NIT -->
                      <span class="font-weight-bold">NIT:</span>
                      {{ customerData.nit }}
                      <br>
                      <!-- Dirección -->
                      <span class="font-weight-bold">Dirección:</span>
                      {{ (customerData.address) ? customerData.address : '' }}
                    </p>
                  </b-col>

                  <!-- Col: Fecha -->
                  <b-col
                    cols="12"
                    xl="6"
                    md="6"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="fecha"
                      rules="required"
                    >
                      <b-form-group>
                        <h6 class="mb-1 mt-1">
                          Fecha:
                        </h6>
                        <b-form-datepicker
                          v-model="saleData.sale_date"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          locale="es"
                          placeholder="Fecha de la venta"
                          selected-variant="warning"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card-body>

              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{height: trHeight}"
                >
                  <b-row>
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <b-col
                      v-if="saleData.products.length > 0"
                      cols="12"
                    >
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <strong>Producto</strong>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <strong>Precio</strong>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <strong>Cantidad</strong>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <strong>Suma</strong>
                          </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>
                    </b-col>
                  </b-row>

                  <b-row
                    v-for="(product, index) in saleData.products"
                    :key="index"
                    ref="row"
                    class="pb-1"
                  >
                    <!-- Item Form -->
                    <b-col cols="12">

                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 pt-1 pl-1 pr-1">
                          <!-- Form Headers -->
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <label class="d-inline d-lg-none">Producto</label>
                            {{ product.label }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <label class="d-inline d-lg-none">Cost</label>
                            <b-form-input
                              v-model="product.price"
                              type="number"
                              min="0.01"
                              step="any"
                              class="mb-2"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <label class="d-inline d-lg-none">Qty</label>
                            <b-form-input
                              v-model="product.quantity"
                              type="number"
                              min="1"
                              step="any"
                              class="mb-2"
                              @change="validateProductStock(product)"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label class="d-inline d-lg-none">Price</label>
                            <p class="mb-1">
                              Q.{{ Number(product.price * product.quantity).toFixed(2) }}
                            </p>
                          </b-col>

                        </b-row>
                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    md="10"
                    class="d-flex justify-content-end"
                  >
                    <div class="sale-total-wrapper">
                      <div
                        class="sale-total-item"
                      >
                        <p class="sale-total-title">
                          Total:
                        </p>
                        <p class="sale-total-amount">
                          Q.{{ saleTotal }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Note -->
              <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">Observaciones: </span>
                <b-form-textarea
                  v-model="saleData.comments"
                  placeholder="Comentarios y anotaciones relacionadas con la compra"
                />
              </b-card-body>

            </b-card>
          </b-col>

          <!-- Tabla de productos disponibles -->
          <b-col
            cols="12"
            md="6"
            xl="6"
          >
            <!-- filters -->
            <b-card no-body>
              <div class="m-2">
                <b-row>
                  <!-- Search -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-input
                      v-model="searchQuery"
                      placeholder="Buscar por nombre"
                    />
                  </b-col>

                  <!-- Categories filter -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-if="categoryOptions"
                      v-model="selectedCategory"
                      :options="categoryOptions"
                      :reduce="category => category.id"
                      placeholder="Ver por categoría"
                      @search="onSearchCategories"
                    >
                      <template slot="no-options">
                        Lo siento, no se encontraron categorías
                      </template>
                    </v-select>
                  </b-col>
                </b-row>

                <b-row class="mt-1">
                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <label>Mostrar</label>
                    <v-select
                      v-model="perPage"
                      :options="perPageProductsOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block ml-50 mr-1"
                    />

                    <span
                      v-if="productsMeta"
                      class="text-muted"
                      style="font-size: 13px"
                    >Mostrando del {{ productsMeta.from }} al {{ productsMeta.to }} de {{ productsMeta.total }} productos</span>

                  </b-col>
                </b-row>
              </div>

              <!-- list -->
              <b-table
                ref="selectableTable"
                selectable
                hover
                show-empty
                empty-text="No hay productos disponibles"
                select-mode="range"
                sticky-header="true"
                :busy="tableIsBusy"
                :items="productsOptions"
                :fields="productFields"
                :per-page="perPage"
                @row-selected="onRowSelected"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle" />
                  </div>
                </template>

                <template #cell(price)="data">
                  Q.{{ data.item.price }}
                </template>

                <!-- Disponible total -->
                <template #cell(stock)="data">
                  <b-badge
                    :variant="(data.item.stock > 0) ? 'light-success' : 'light-danger'"
                  >
                    {{ (data.item.stock) ? data.item.stock : '0' }}
                  </b-badge>
                </template>

              </b-table>

              <div class="mx-2 mb-2">
                <b-row>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                    <b-pagination
                      v-if="productsMeta"
                      v-model="currentPage"
                      :total-rows="productsMeta.total"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>

                </b-row>
              </div>

              <div class="m-2">
                <b-col
                  sm="12"
                  md="12"
                  xl="12"
                  style="float:right;"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    variant="warning"
                    block
                    :disabled="!saleData.products.length || (!saleData.customer_id || saleData.customer_id === '')"
                    @click="getPaid()"
                  >
                    Realizar cobro
                  </b-button>
                </b-col>
              </div>

            </b-card>
          </b-col>
        </b-row>

        <!-- modal -->
        <b-modal
          ref="modal-payment_received"
          hide-footer
          title="Realizar cobro"
        >
          <!-- fila con dos columnas -->
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label="Tipo de pago">
                <b-form-radio-group
                  v-model="saleData.payment_type"
                  :options="payOptions"
                  name="pay-types"
                  stacked
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label="Tipo de venta">
                <b-form-radio-group
                  v-model="saleData.sale_type"
                  :options="saleOptions"
                  name="sale-types"
                  stacked
                  @change="onSaleTypeChange"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <!-- Pedir dirección si tipo de venta es envío -->
              <b-form-group
                v-if="saleData.sale_type === 'envio'"
                label="Dirección"
              >
                <b-form-input
                  v-model="saleData.delivery_address"
                  placeholder="Dirección de envío"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group label="Total a pagar">
                <h1 class="font-weight-bold">
                  Q.{{ saleTotal }}
                </h1>
              </b-form-group>
              <div
                v-if="saleData.payment_type === 'efectivo'"
              >
                <!-- ingresa cantidad pagada -->
                <b-form-group label="Pago">
                  <b-form-input
                    v-model="saleData.payment_received"
                    type="number"
                    min="0"
                    step="0.01"
                    placeholder="0.00"
                  />
                </b-form-group>

                <!-- muestra cambio calculado -->
                <b-form-group label="Cambio">
                  <h1 class="font-weight-bold">
                    Q.{{ saleChange }}
                  </h1>
                </b-form-group>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-button
                v-if="(saleData.payment_type === 'efectivo' && Number(saleData.payment_received) >= Number(saleTotal)) || saleData.payment_type !== 'efectivo'"
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="warning"
                block
                @click="saveData()"
              >
                Confirmar
              </b-button>
            </b-col>
          </b-row>

        </b-modal>

        <!-- Customer form -->
        <customers-add
          :customer-add-sidebar-active.sync="customerAddSidebarActive"
          @refreshData="getCustomers"
        />

      </b-form>
    </validation-observer>

  </section>
</template>

<script>
import axios from '@axios'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import CustomersAdd from '../../contacts/customers/CustomersAdd.vue'

export default {
  components: {
    vSelect,
    CustomersAdd,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],

  data() {
    return {
      // Sale
      saleData: {
        customer_id: '',
        sale_date: this.getCurrentDate(),
        comments: '',
        payment_type: 'efectivo',
        sale_type: 'local',
        delivery_address: '',
        payment_received: '',
        products: [],
      },

      payOptions: [
        { text: 'Efectivo', value: 'efectivo' },
        { text: 'Tarjeta', value: 'tarjeta' },
        { text: 'Crédito', value: 'credito' },
      ],
      saleOptions: [
        { text: 'En Tienda', value: 'local' },
        { text: 'Envío', value: 'envio' },
      ],

      // Detail template
      formProductBlank: {
        product_id: null,
        cost: 0,
        quantity: 0,
      },

      // customers
      customersOptions: [],
      customerAddSidebarActive: false,

      // Products
      productsOptions: [],
      productFields: [
        { key: 'label', label: 'Producto' },
        { key: 'price', label: 'Precio' },
        { key: 'stock', label: 'Disp.' },
      ],
      selectedProducts: [],
      show: true,
      searchQuery: '',
      categoryOptions: [],
      selectedCategory: '',

      customerSelectLabel: 'label',
      customerData: {},

      // pagination
      perPage: 10,
      perPageProductsOptions: [5, 10, 25, 50, 100, 'Todos'],
      currentPage: 1,
      productsMeta: null,

      sortField: 'id',
      sortDesc: 'desc',

      tableIsBusy: true,
    }
  },

  computed: {
    saleTotal() {
      let total = 0
      this.saleData.products.forEach(element => {
        total += element.price * element.quantity
      })
      return Number(total).toFixed(2)
    },

    saleChange() {
      // validar que sea efectivo y que el pago sea mayor a cero
      if (this.saleData.payment_type === 'efectivo' && Number(this.saleData.payment_received) > 0) {
        // validar que el pago sea mayor o igual al total
        if (Number(this.saleData.payment_received) >= this.saleTotal) {
          return Number(this.saleData.payment_received - this.saleTotal).toFixed(2)
        }
      }
      return 0
    },
  },

  watch: {
    selectedCategory() {
      this.tableIsBusy = true
      this.getProducts()
    },
    searchQuery() {
      this.tableIsBusy = true
      this.getProducts()
    },

    // pagination
    perPage() {
      this.tableIsBusy = true
      this.getProducts()
    },
    currentPage() {
      this.tableIsBusy = true
      this.getProducts()
    },
  },

  mounted() {
    this.initTrHeight()
  },

  created() {
    this.getCustomers()
    this.getProducts()
    this.getCategories()
    window.addEventListener('resize', this.initTrHeight)
  },

  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  methods: {
    onSaleTypeChange(value) {
      if (value === 'envio') {
        if (this.customerData.address !== '-') {
          this.saleData.delivery_address = this.customerData.address
        } else {
          this.saleData.delivery_address = ''
        }
      }
    },

    getPaid() {
      if (this.saleData.customer_id && this.saleData.customer_id !== '') {
        this.$refs['modal-payment_received'].show()
      } else {
        this.makeToast('danger', 'Falta el cliente', 'Seleccione el cliente')
      }
    },

    hideModal() {
      this.$refs['modal-payment_received'].hide()
    },

    validateProductStock(item) {
      // ejecutar servicio para obtener stock por tienda
      axios
        .get(`product/${item.product_id}`)
        .then(response => {
          // validar si lo requerido es mayor al stock disponible
          if (item.quantity > Number(response.data.data.stock)) {
            this.makeToast('danger', 'Sin Stock', `El artículo: ${item.label}, no tiene stock suficiente.`)
          } else {
            // validar si el articulo tiene stock en la tienda actual
            let stockCurrentStore = 0
            response.data.data.stock_by_store.forEach(element => {
              if (element.store === this.userData().store) {
                stockCurrentStore = element.stock_store
              }
            })
            if (item.quantity > Number(stockCurrentStore)) {
              this.makeToast('danger', 'Sin Stock', `El artículo: ${item.label}, no tiene stock suficiente en la tienda actual.`)
            } else {
              //
            }
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    onRowSelected(items) {
      this.selectedProducts = items
      if (items[0]) {
        if (items[0].stock > 0) {
          this.addItem()
        } else {
          this.makeToast('danger', 'Sin Stock', `El artículo: ${items[0].label}, no tiene stock suficiente.`)
        }
      }
    },

    getCustomers() {
      this.customersOptions = []
      axios
        .get('customer?perPage=10&sortField=id&sortDesc=desc&filterField=status&filterValue=Y')
        .then(response => {
          response.data.data.forEach(element => {
            this.customersOptions.push({
              label: element.name,
              id: element.id,
              nit: element.nit,
            })
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    isNIT(value) {
      if (/^\d+$/.test(value) || value === 'CF') {
        return true
      }
      return false
    },

    onSearchCustomers(search, loading) {
      // validar si search es un numero
      if (this.isNIT(search)) {
        this.customerSelectLabel = 'nit'
      } else {
        this.customerSelectLabel = 'label'
      }

      if (search.length) {
        loading(true)
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          axios
            .get(`customer?filterField=status&filterValue=Y&query=${search}`)
            .then(response => {
              loading(false)
              this.customersOptions = []
              response.data.data.forEach(element => {
                this.customersOptions.push({
                  label: element.name,
                  id: element.id,
                  nit: element.nit,
                })
              })
            })
            .catch(error => {
              this.showErrors(error)
            })
        }, 300)
      } else {
        this.getCustomers()
      }
    },

    setCustomerData(customer) {
      if (customer) {
        // get customer
        axios
          .get(`customer/${customer.id}`)
          .then(response => {
            this.saleData.customer_id = response.data.data.id
            this.customerData = response.data.data
          })
          .catch(error => {
            this.showErrors(error)
          })
      }
    },

    getProducts() {
      if (this.perPage === 'Todos') {
        this.perPage = this.productsMeta.total
      }

      const filterArray = [
        { field: 'status', value: 'Y' },
        { field: 'category_id', value: this.selectedCategory || '' },
      ]

      axios
        .get(`product?perPage=${this.perPage}&page=${this.currentPage}&sortField=id&sortDesc=desc&filters=${JSON.stringify(filterArray)}&query=${this.searchQuery}&sortField=${this.sortField}&sortDesc=${this.sortDesc}`)
        .then(response => {
          this.tableIsBusy = false

          this.productsOptions = []
          response.data.data.forEach(element => {
            this.productsOptions.push({
              label: element.name,
              id: element.id,
              cost: element.cost,
              price: element.price,
              stock: element.stock,
              by_store: element.stock_by_store,
              description: element.description,
            })

            this.productsMeta = response.data.meta
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    onSearchProducts(search, loading) {
      this.productsOptions = []

      const filterArray = [
        { field: 'status', value: 'Y' },
      ]

      if (search.length) {
        loading(true)
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          axios
            .get(`product?filters=${JSON.stringify(filterArray)}&query=${search}`)
            .then(response => {
              loading(false)
              this.productsOptions = []
              response.data.data.forEach(element => {
                this.productsOptions.push({
                  label: element.name,
                  id: element.id,
                  cost: element.cost,
                  price: element.price,
                  stock: element.stock,
                  description: element.description,
                })
              })
            })
            .catch(error => {
              this.showErrors(error)
            })
        }, 300)
      } else {
        this.getProducts()
      }
    },

    getCategories() {
      axios
        .get('category?perPage=100&sortField=id&sortDesc=desc&filterField=status&filterValue=Y')
        .then(response => {
          response.data.data.forEach(element => {
            this.categoryOptions.push({
              label: element.name,
              id: element.id,
            })
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    onSearchCategories(search, loading) {
      this.categoryOptions = []
      if (search.length) {
        loading(true)
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          axios
            .get(`category?filterField=status&filterValue=Y&query=${search}`)
            .then(response => {
              loading(false)
              this.categoryOptions = []
              response.data.data.forEach(element => {
                this.categoryOptions.push({
                  label: element.name,
                  id: element.id,
                })
              })
            })
            .catch(error => {
              this.showErrors(error)
            })
        }, 300)
      } else {
        this.getCategories()
      }
    },

    saveData() {
      // si el tipo de venta es envio y el cliente no tiene direccion
      if (this.saleData.sale_type === 'envio' && this.saleData.delivery_address === '') {
        this.makeToast('danger', 'Error', 'El cliente debe tener una dirección para realizar una venta de envio.')
        return
      }

      axios
        .post('sale', this.saleData)
        .then(async response => {
          if (response.data.res) {
            this.clearForm()
            this.makeToast('success', 'Nuevo ingreso', `Se ha realizado el ingreso correctamente, transacción: #${response.data.data.id}.`)
            await new Promise(resolve => { window.setTimeout(resolve, 500) })
            this.$emit('addSaleTab', response.data.data.id)
          } else {
            throw (response)
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    clearForm() {
      // Reset our form values
      this.saleData.customer_id = ''
      this.saleData.sale_date = ''
      this.saleData.comments = ''
      this.saleData.products = []
      this.saleData.payment_type = 'efectivo'
      this.saleData.sale_type = 'local'
      this.saleData.delivery_address = ''
      this.saleData.payment_received = ''
      this.searchQuery = ''
      this.selectedCategory = ''

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },

    userData() {
      return JSON.parse(localStorage.getItem('FerreAuthUser'))
    },

    // Métodos para manejar productos dinamicamente
    // =======================================================
    addItem() {
      this.$refs.form.style.overflow = 'hidden'

      const foundItem = this.saleData.products.find(element => element.product_id === this.selectedProducts[0].id)
      if (foundItem) {
        this.makeToast('danger', 'Producto ya seleccionado', `El producto ${this.selectedProducts[0].label} ya fue seleccionado anteriormente.`)
      } else {
        this.saleData.products.push({
          label: this.selectedProducts[0].label,
          product_id: this.selectedProducts[0].id,
          price: this.selectedProducts[0].price,
          quantity: 1,
        })

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
          setTimeout(() => {
            this.$refs.form.style.overflow = null
          }, 350)
        })
      }
    },

    removeItem(index) {
      this.saleData.products.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    // =======================================================
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.sale-add-wrapper {
  .add-new-customer-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.sale-total-wrapper {
  width: 100%;
  max-width: 10rem;
  .sale-total-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2rem;
    .sale-total-title {
      margin-bottom: 0.35rem;
    }
    .sale-total-amount {
      margin-bottom: 0.35rem;
      font-weight: 600;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

.per-page-selector {
  width: 90px;
}
</style>
