<template>
  <b-tabs
    v-model="tabIndex"
    content-class="mt-1"
  >
    <!-- This tabs content will always be mounted -->
    <b-tab
      title="Historial de ventas"
      lazy
    >
      <sale-list
        :key="saleListKey"
        @addSaleTab="newTab($event)"
      />
    </b-tab>

    <!-- This tabs content will not be mounted until the tab is shown -->
    <!-- and will be un-mounted when hidden -->
    <b-tab
      title="Nueva venta"
      lazy
    >
      <sale-add
        @changeTab="changeTab($event)"
        @addSaleTab="newTab($event)"
      />
    </b-tab>

    <!-- Dynamic tab for sale view -->
    <b-tab
      v-for="sale in salesTabs"
      :key="'sale-tab-' + sale"
      :title="'Venta #' + sale"
      lazy
    >
      <sale-view :sale-id="sale" />
    </b-tab>

    <!-- Close tab button -->
    <template
      v-if="salesTabs.length > 0"
      #tabs-end
    >
      <b-nav-item
        @click.prevent="closeTab(salesTabs[0])"
      >
        <b>x</b>
      </b-nav-item>
    </template>

  </b-tabs>
</template>

<script>
import axios from '@axios'
import SaleList from './SalesList.vue'
import SaleAdd from './SalesAdd.vue'
import SaleView from './SaleView.vue'

export default {
  components: {
    SaleList,
    SaleAdd,
    SaleView,
  },

  data() {
    return {
      tabIndex: 1,
      saleListKey: 0,
      salesTabs: [],

      cashData: {},
    }
  },

  async mounted() {
    await this.getCashRegister()

    // si existe un id de caja cerrada, se redirige a menu caja
    if (this.cashData.id && this.cashData.status === 'closed') {
      this.$router.push({ name: 'transactions-cash-register' })
      // Si no existe una caja abierta, se redirige a menu caja
    } else if (!this.cashData.id) {
      this.$router.push({ name: 'transactions-cash-register' })
    }
  },

  methods: {
    // Consultar si existen cajas abiertas
    async getCashRegister() {
      const store = JSON.parse(localStorage.getItem('FerreAuthUser')).store_id

      const filterArray = [
        { field: 'store_id', value: store },
        { field: 'created_at', value: this.getCurrentDate() },
      ]

      await axios
        .get(`cash-register?filters=${JSON.stringify(filterArray)}`)
        .then(response => {
          if (response.data.data.length > 0) {
            this.cashData.id = response.data.data[0].id
            this.cashData.status = response.data.data[0].status
            this.cashData.closed_at = response.data.data[0].closed_at
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    changeTab(tabIndex) {
      this.tabIndex = tabIndex // Change tab
      this.saleListKey += 1 // Re-Render component
    },

    newTab(saleId) {
      this.salesTabs = []
      this.salesTabs.push(saleId)
      setTimeout(() => {
        this.tabIndex = 2
      }, 100)
    },

    closeTab(x) {
      for (let i = 0; i < this.salesTabs.length; i += 1) {
        if (this.salesTabs[i] === x) {
          this.salesTabs.splice(i, 1)
        }
      }
      this.tabIndex = 0 // Redirecto to list
    },
  },
}
</script>
